.loginScreen {
    position: relative;
    height: 100%;
    background: url("https://springboard-cdn.appadvice.com/wp-content/appadvice-v2-media/2016/11/Netflix-background_860c8ece6b34fb4f43af02255ca8f225-xl.jpg") center no-repeat;
    background-size: cover;
}

.loginScreen__body > h1 {
    font-size: 3.125rem;
    margin-bottom: 20px;
}

.loginScreen__body > h2 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 30px;
}

.loginScreen__body > h3 {
    font-size: 1.3rem;
    font-weight: 400;
}

.loginScreen__body {
    position: absolute;
    top: 30%;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: #fff;
    padding: 20px;
    left: 0;
    right: 0;
}

.loginScreen__gradient {
    width: 100%;
    z-index: 1;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8)100%);
}

.loginScreen__button {
    position: fixed;
    right: 20px;
    top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #e50914;
    font-weight: 600;
    border: none;
    cursor: pointer;
}

.loginScreen__logo {
    position: fixed;
    left: 0;
    width: 150px;
    object-fit: contain;
    padding-left: 20px;
}

.loginScreen__input > form > input {
    padding: 10px;
    outline-width: 0;
    height: 30px;
    width: 30%;
    border: none;
    max-width: 600px;
}

.loginScreen__input {
    margin: 20px;
}

.loginScreen__getStarted {
    padding: 16px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #e50914;
    border: none;
    font-weight: 600;
    cursor: pointer;
}

.loginScreen__input > form {
    display: flex;
    align-items:center;
    justify-content: center;
}