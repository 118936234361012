.nav {
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 30px;
    z-index: 1;

    /* animation */
    transition-timing-function: ease-in;
    transition: all 0.5s;
}

.nav__contents {
    display: flex;
    justify-content: space-between;
}

.nav__black {
    background-color: #111;
}

.nav__logo {
    position: fixed;
    top: 10px;
    left: 0;
    width: 80px;
    object-fit: contain;
    padding-left: 30px;
    cursor: pointer;
}

.nav__avatar {
    cursor: pointer;
    position: fixed;
    right: 30px;
    width: 30px;
    height: 30px;
}

