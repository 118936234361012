.signupScreen {
    max-width: 300px;
    padding: 70px;
    margin-left: auto;
    margin-right: auto;
    background: rgba(0, 0, 0, 0.85);
}

.signupScreen > form > h1 {
    text-align: left;
    margin-bottom: 25px;
}

.signupScreen__gray {
    color: gray;
}

.signupScreen > form > h4 {
    text-align: left;
    margin-top: 30px;
}

.signupScreen > form {
    display: grid;
    flex-direction: column;
}

.signupScreen > form > input {
    outline-width: 0;
    height: 40px;
    margin-bottom: 14px;
    border-radius: 5px;
    border: none;
    padding: 5px 15px;
}

.signupScreen > form > button {
    padding: 16px 20px;
    font-size: 1rem;
    color: #fff;
    border-radius: 5px;
    background-color: #e50914;
    font-weight: 600;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.signupScreen__link:hover {
    cursor: pointer;
    text-decoration: underline;
}