.row {
    color: white;
    margin-left: 20px;
}

.row__posters {
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
}

 /* Hide scrollbar for Chrome, Safari and Opera */
.row__posters::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.row__posters {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.row__poster {
    max-height: 100px;
    object-fit: contain;
    margin-right: 10px;
    transition: transform 450ms;
}

.row__poster:hover {
    transform: scale(1.08);
    opacity: 1;
}

.row__posterLarge {
    max-height: 250px;
}

.row__posterLarge:hover {
    transform: scale(1.09);
    opacity: 1;
}